import i18n, { tf } from '@/i18n';
import { CountryType } from '@/stores/CommonStore/types';
import Cookie from 'js-cookie';
import dayjs from 'dayjs';

const StringUtils = {
  /**
   * 转译正则表达式中的特殊字符
   * @params value 需要转译的目标值
   * @returns value 转译后的值
   */

  addEscape: (value: string) => {
    let arr = [
      '(',
      '[',
      '{',
      '/',
      '^',
      '$',
      '¦',
      '}',
      ']',
      ')',
      '?',
      '*',
      '+',
      '.',
      "'",
      '"',
      '\\',
    ];
    for (let i = 0; i < arr.length; i++) {
      if (value) {
        if (value.indexOf(arr[i]) > -1) {
          const reg = (str: any) => str?.replace(/[[\\\]/{}()*'"¦+?.\\\\^$|]/g, '\\$&');
          value = reg(value);
        }
      }
    }
    return value;
  },

  /**
   * 关键字变色
   * @params content 内容
   * @params keyword 关键词
   * @params tagName 标签名
   */

  warpTag: (
    content: any,
    keyword: any,
    tagName: any,
    color: string,
    href?: string,
    target?: string
  ) => {
    if (content === 'No results') {
      return content;
    }
    const a = content?.toLowerCase();
    const b = keyword?.toLowerCase();

    const indexOf = a?.indexOf(b);
    const c = indexOf > -1 ? content?.substr(indexOf, keyword.length) : '';
    const val = `<${tagName} href="${href}" target="${target}"  style="color:${color};">${c}</${tagName}>`;
    const regS1 = new RegExp(keyword, 'gi');
    const regS = new RegExp(StringUtils.addEscape(keyword), 'gi'); // 转译keyword中的特殊字符
    const str = tagName !== 'a' ? content?.replace(regS, val) : content?.replace(regS1, val);
    return str;
  },

  // 展示时间 00:00
  displayDuration: (duration: number) => {
    const hours = parseInt((duration / 3600).toString(), 10)
      .toString()
      .padStart(2, '0');
    const minutes = parseInt(((duration % 3600) / 60).toString(), 10)
      .toString()
      .padStart(2, '0');
    const seconds = parseInt((duration % 60).toString(), 10)
      .toString()
      .padStart(2, '0');
    return `${duration > 3600 ? `${hours}:` : ''}${minutes}:${seconds}`;
  },

  /**
   * 将数字转为以万为单位
   * @params value 需要转换的数字或字符串
   * @params decimal 非万为单位时需要保留几位小数,默认保留两位
   */
  handleThousand: (value: any, float: number = 1) => {
    let num = Number(value);
    if (num === 0) {
      return num + '';
    } else if (num >= 1 && num < 10000) {
      // return num.toFixed(float) + '';
      return num + '';
    } else {
      // 保留一位小数，不进行四舍五入
      value = (num / 10000).toString();
      let index = value.indexOf('.');
      if (index !== -1) {
        value = value.substring(0, float + index + 1);
      } else {
        value = value.substring(0);
      }
      return parseFloat(value).toFixed(float) + 'w';
    }
  },

  /**
   * 转为百分数并保留两位小数
   * @params value 需要处理的值
   * @return percent 保留两位小数的百分数
   */
  toPercent: (value: number | string | undefined) => {
    if (value === undefined) value = 0;
    if (value === -1) return (value = '-');
    let percent = (Number(value) * 100).toFixed(2);
    percent += '%';
    return percent;
  },

  /**
   * 打开新的页面
   * @params url 跳转的目标路径
   */
  handleOpenNewPage: (url: string) => {
    const w = window.open('about:blank');
    if (url) {
      w!.location.href = url;
    } else {
      w!.location.href = '404';
    }
  },

  /**
   * 处理手机号  传入 86-18888888888 返回 +8618888888888
   */
  handleChannelTalkPhone: (phoneNumber?: string) => {
    if (!phoneNumber) {
      return '';
    }
    const countryCode = phoneNumber.split('-')[0];
    const phoneNum = phoneNumber.split('-')[1];
    const phone = '+' + countryCode + phoneNum;
    return phone;
  },

  /**
   * 展示手机号  传入 86-18888888888 展示 188*****888
   */
  displayPhone: (phoneNumber?: string) => {
    if (!phoneNumber) {
      return '-';
    }
    const phoneNum = phoneNumber.split('-')[1];
    const phone =
      phoneNum.slice(0, 3) +
      '******************'.slice(0, phoneNum.length - 6) +
      phoneNum.slice(phoneNum.length - 3, phoneNum.length);
    return phone;
  },

  /**
   * 展示手机号  传入 86-18888888888 展示 +86 18888888888
   */
  displayPhoneNumber: (phoneNumber?: string) => {
    if (!phoneNumber) {
      return '';
    }
    const countryCode = phoneNumber.split('-')[0];
    const phoneNum = phoneNumber.split('-')[1];
    const phone = '+' + countryCode + ' ' + phoneNum;
    return phone;
  },

  /**
   * 展示邮箱  传入 piaodayi@adoba.net 展示 pia*****@adoba.net
   */
  displayEmail: (emailString?: string) => {
    if (!emailString) {
      return '-';
    }
    const emailStr = emailString.split('@')[0];
    const email = emailStr.slice(0, 3) + '***@' + emailString.split('@')[1];
    return email;
  },

  // 点赞数量展示
  displayCount: (count?: number | string) => {
    let countNum = count || 0;
    if (!countNum) {
      return '-';
    } else {
      countNum = Number(countNum);
    }
    let countStr = countNum.toString();
    if (countNum > 999999999) {
      countStr = (countNum / 1000000000).toFixed(1) + 'B';
    } else if (countNum > 999999) {
      countStr = (countNum / 1000000).toFixed(1) + 'M';
    } else if (countNum > 999) {
      countStr = (countNum / 1000).toFixed(1) + 'K';
    }
    return countStr;
  },

  /**
   * 根据合同状态显示‘运营中’‘解约’‘休眠’及对应颜色
   * @params value 合同的类型：1,2,3
   * @params type 需要展示文字或者颜色
   */
  handleContractStatus: (value: number, type: 'text' | 'color') => {
    let status: string = '';
    let view: string = '#ccc';

    switch (value) {
      case 0:
        status = '运营中';
        view = '#1db100';
        break;
      case 1:
        status = '解约';
        view = '#929292';
        break;
      case 2:
        status = '未签约';
        view = '#ccc';
        break;
      case 3:
        status = '未运营';
        view = '#feae00';
        break;
      default:
        status = '-';
        break;
    }
    if (type === 'text') return status;
    if (type === 'color') return view;
  },

  /**
   * 处理日期显示格式
   * @params date 需要处理的日期
   * @return dateShow 处理后需要展示的日期格式
   */
  handleDateShow: (date?: string) => {
    let dateShow = '-';
    if (date && date !== '0001-01-01T00:00:00Z') {
      dateShow = dayjs(date).format('YYYY-MM-DD');
    } else {
      dateShow = '-';
    }
    return dateShow;
  },

  /**
   * 处理文件显示格式
   * @params size 文件大小
   * @return formart 处理后需要展示的文件大小
   */
  handleFileSize: (size: number, digit = 2) => {
    let fileSize = '';
    if (size >= 1024 * 1024 * 1024) {
      fileSize = (size / 1024 / 1024 / 1024).toFixed(digit) + 'GB';
    } else if (size >= 1024 * 1024) {
      fileSize = (size / 1024 / 1024).toFixed(digit) + 'MB';
    } else if (size >= 1024) {
      fileSize = (size / 1024).toFixed(digit) + 'KB';
    } else if (size < 1024) {
      fileSize = size + 'bit';
    }
    return fileSize;
  },

  /**
   * 处理文件显示格式
   * @params size 文件大小
   * @return formart 处理后需要展示的文件大小
   */
  handleUploadFileSize: (size = 0, target: string) => {
    let fileSize = '';
    if (target.includes('bit')) {
      fileSize = size + 'bit';
    } else if (target.includes('KB')) {
      fileSize = (size / 1024).toFixed(2) + 'KB';
    } else {
      if (size >= 1024 * 1024 * 1024) {
        fileSize = (size / 1024 / 1024 / 1024).toFixed(2) + 'GB';
      } else {
        fileSize = (size / 1024 / 1024).toFixed(2) + 'MB';
      }
    }
    return fileSize;
  },

  /**
   * 处理选择国籍的翻译
   * @param language 当前选择的语言
   * @param item CountryType
   * @returns 根据当前选择的语言返回对应的语言
   */
  handleCountryName: (language: string = 'zh', item: CountryType) => {
    let nameLang: string = item?.zh;
    switch (language) {
      case 'zh':
        nameLang = item?.zh;
        break;
      case 'en':
        nameLang = item?.en;
        break;
      case 'ko':
        nameLang = item?.ko;
        break;
      default:
        nameLang = item?.zh;
        break;
    }
    return nameLang;
  },

  /**
   * 处理合同类型的显示
   * @param type 返回的合同类型
   * @returns 需要展示的合同类型名称
   */
  handleContractTypeShow: (type?: number) => {
    let typeName: string = '-';
    switch (type) {
      case 1:
        typeName = tf('account.contract_type_personal'); // 个人
        break;
      case 2:
        typeName = tf('account.contract_type_enterprise'); // 企业
        break;
      case 3:
        typeName = tf('account.contract_type_mcn'); // MCN
        break;
      default:
        typeName = '-';
        break;
    }
    return typeName;
  },

  /**
   * 获取当前项目环境
   * @returns env 'dev' | 'prod'
   */
  getEnvironment: () => {
    const currentHost: string = window.location.host;
    switch (currentHost) {
      case 'www.adobaro.com': // 生产环境
      case 'v2.adobaro.com': // 生产环境
      case 'adobaro.com': // 生产环境
      case 'allen.adoba.tv': // 临时 生产环境
        return 'prod';
      default:
        return 'dev';
    }
  },

  /**
   * 清除cookies
   */
  removeCookies: () => {
    Cookie.remove('enterprise_token');
    Cookie.remove('enterprise_refresh_token');
    Cookie.remove('ch-veil-id', { path: '', domain: 'adobaro.com' });
    Cookie.remove('ch-veil-id', { path: '', domain: 'www.adobaro.com' });
    Cookie.remove('ch-session-99516', { path: '', domain: 'adobaro.com' });
    Cookie.remove('ch-session-99516', { path: '', domain: 'www.adobaro.com' });
  },

  /**
   * 素材管理文件大小
   * @param value
   * @returns
   */
  handleMaterialFileSizeUnit: (value: string | number) => {
    let fileSize = '';
    let size = Number(value);

    if (size === 0) {
      fileSize = '0.00GB';
    } else if (size >= 1024 * 1024 * 1024) {
      fileSize = (size / 1024 / 1024 / 1024).toFixed(2) + 'GB';
    } else if (size >= 1024 * 1024) {
      fileSize = (size / 1024 / 1024).toFixed(2) + 'MB';
    } else if (size >= 1024) {
      fileSize = (size / 1024).toFixed(2) + 'KB';
    } else if (size < 1024) {
      fileSize = size + 'bit';
    }

    return fileSize;
  },

  /**
   * 根据当前语言打开 各个平台的协议页面
   * @param type 类别名称
   * @param platformId 平台id
   * @returns 需要打开的目标链接地址
   */
  handlePlatformProtocol: (type: 'user' | 'privacy', platformId: number) => {
    const list = [
      {
        name: 'platform_hao_kan',
        platformId: 1,
        user: {
          zh: 'https://baijiahao.baidu.com/docs/#/markdownsingle/BaiJiaHaoFuWuXieYi',
          en: 'https://vr0omf0e2s.feishu.cn/docx/XVfrdcgu1obBN4xA05rc1iUznfd',
          ko: 'https://vr0omf0e2s.feishu.cn/docx/GmxzdTT1oo8ThixogGlcIZNTnLd',
        },
        privacy: {
          zh: 'http://privacy.baidu.com/policy',
          en: 'https://vr0omf0e2s.feishu.cn/docx/Dgeed03ScofCm1xXsNkcDiPCn7c',
          ko: 'https://vr0omf0e2s.feishu.cn/docx/ZoNWdAhKQoEDTFxFsBGcKYUOnng',
        },
      },
      {
        name: 'platform_iqiyi',
        platformId: 3,
        user: {
          zh: 'https://www.iqiyi.com/user/register/protocol.html',
          en: 'https://vr0omf0e2s.feishu.cn/docx/CEbTdOwhpoJIWwxnD0Qc7KeTnXf',
          ko: 'https://vr0omf0e2s.feishu.cn/docx/YhCIdB5XAomYiYxupZjcUOQbnEc',
        },
        privacy: {
          zh: 'https://privacy.iqiyi.com/policies',
          en: 'https://vr0omf0e2s.feishu.cn/docx/IP3Rdp2fdos1UZxLd37cQrDjntg',
          ko: 'https://vr0omf0e2s.feishu.cn/docx/UKvbd8YVQoMNpFxY91mcB2oInPf',
        },
      },
      {
        name: 'platform_bilibili',
        platformId: 4,
        user: {
          zh: 'https://www.bilibili.com/protocal/licence.html',
          en: 'https://vr0omf0e2s.feishu.cn/docx/XFpIdve6MoGHhCxUWCmctXIEnJt',
          ko: 'https://vr0omf0e2s.feishu.cn/docx/IOBQd65rboyrNCxxZqjcw7oanWg',
        },
        privacy: {
          zh: 'https://www.bilibili.com/blackboard/privacy-pc.html',
          en: 'https://vr0omf0e2s.feishu.cn/docx/T8NndtEkDoT01txGcK7ceygPnZc',
          ko: 'https://vr0omf0e2s.feishu.cn/docx/DMsXdudVkoohBtxp6mZcnkd7nIh',
        },
      },
      {
        name: 'platform_xi_gua',
        platformId: 5,
        user: {
          zh: 'https://mp.toutiao.com/profile_v4_public/public/protocol/agreement',
          en: 'https://vr0omf0e2s.feishu.cn/docx/TiAjdPu60oOuOsxHQmeckFPpnq3',
          ko: 'https://vr0omf0e2s.feishu.cn/docx/I5MsdeUwiobzLMxuLO3ctlw3nNe',
        },
        privacy: {
          zh: 'https://mp.toutiao.com/profile_v4_public/public/protocol/privacy',
          en: 'https://vr0omf0e2s.feishu.cn/docx/C9Had888oogyvVx3zj9cpeelnQf',
          ko: 'https://vr0omf0e2s.feishu.cn/docx/QZAVdzqsioSRxvxwLmtcyX08nkg',
        },
      },
      {
        name: 'platform_wei_bo',
        platformId: 6,
        user: {
          zh: 'https://weibo.com/signup/v5/protocol',
          en: '',
          ko: '',
        },
        privacy: {
          zh: 'https://weibo.com/signup/v5/privacy',
          en: '',
          ko: '',
        },
      },
      {
        name: 'platform_red_book',
        platformId: 9,
        user: {
          zh: 'https://agree.xiaohongshu.com/h5/terms/ZXXY20220331001/-1',
          en: 'https://vr0omf0e2s.feishu.cn/docx/SH4Sde88co6byExdpNzchHQXnyc',
          ko: 'https://vr0omf0e2s.feishu.cn/docx/GrYrdS321ox7H5xDehKcCuDtnxd',
        },
        privacy: {
          zh: 'https://agree.xiaohongshu.com/h5/terms/ZXXY20220509001/-1',
          en: 'https://vr0omf0e2s.feishu.cn/docx/HAKYdkwwDo3uShx8U0AcB5EenVe',
          ko: 'https://vr0omf0e2s.feishu.cn/docx/WpFSdoa0Eo1yBWxfE4DcOjkIntc',
        },
      },
      {
        name: 'platform_kuai_shou',
        platformId: 10,
        user: {
          zh: 'https://www.kuaishou.com/about/policy?tab=protocol',
          en: 'https://vr0omf0e2s.feishu.cn/docx/UgQQdW2TrouVjWxbfDCcUcevnLf',
          ko: 'https://vr0omf0e2s.feishu.cn/docx/BI4UdxCOvoY7dxxST7OcUpq5nob',
        },
        privacy: {
          zh: 'https://www.kuaishou.com/about/policy?tab=privacy',
          en: 'https://vr0omf0e2s.feishu.cn/docx/SBGRd47q8opugKx0OH9c0UB1nwc',
          ko: 'https://vr0omf0e2s.feishu.cn/docx/BDBddxD7gol2QnxWOxQcrymsnQe',
        },
      },
      {
        name: 'platform_qq_world',
        platformId: 16,
        user: {
          zh: 'https://rule.tencent.com/rule/preview/a880964c-66e7-4556-8a39-827f92b2b355',
          en: '',
          ko: '',
        },
        privacy: {
          zh: 'https://privacy.qq.com/document/preview/33e5320e241645329f9e7cb2a81ec68e',
          en: '',
          ko: '',
        },
      },
    ];

    const platform = list.find((item) => item.platformId === platformId);
    if (platform) {
      const language = i18n.language === 'zh' ? 'zh' : i18n.language === 'ko' ? 'ko' : 'en';
      const docLink =
        platform[type][language] !== '' ? platform[type][language] : platform[type]['zh'];

      return docLink;
    }
    return '';
  },

  /**
   * 根据当前语言打开 adoba协议和帮助中心页面
   * @param type 类别名称
   * @returns 需要打开的目标链接地址
   */
  handleOpenProtocolOrHelp: (
    type:
      | 'user' // 用户协议
      | 'privacy' // 隱私政策
      | 'marketing' // 营销通知
      | 'operating' // 平台运营协议
      | 'planContract' // 会员支付使用合同
      | 'planPrivacy' // 会员支付个人信息政策
      | 'trial' // 体验版协议
      | 'home' // 帮助中心首页
      | 'point' // 金币规则
      | 'auth' // Global pass认证
      | 'channel' // channel
      | 'plan' // 上传视频次数限制规则
      | 'platform' // 是否为平台
      | 'bilibili' // bilibili
      | 'xiaohongshu', // xiaohongshu
    platformId?: number
  ) => {
    // 帮助中心网址列表
    const helpCenterList = [
      {
        type: 'user',
        name: 'protocol_user', // 用户协议
        en: '/user-agreementEN',
        ko: '/user-agreementKO',
        zh: '/user-agreementEN',
      },
      {
        type: 'privacy',
        name: 'protocol_privacy',
        en: '/privacy-policyEN',
        ko: '/privacy-policyKO',
        zh: '/privacy-policyEN',
      },
      {
        type: 'marketing',
        name: 'protocol_marketing',
        en: '/marketing-consentEN',
        ko: '/marketing-consentKO',
        zh: '/marketing-consentEN',
      },
      {
        type: 'operating',
        name: '平台运营协议',
        en: 'https://vr0omf0e2s.feishu.cn/docx/XxA3dhr1xoRhNoxPgBhcSXo5nhg',
        ko: 'https://vr0omf0e2s.feishu.cn/docx/T61Ad6upEoijBMxHEGLcrdu3n7d',
        zh: 'https://vr0omf0e2s.feishu.cn/docx/LpLPddHnNopp4fxJhbJc3emxnmW',
      },
      {
        type: 'trial',
        name: '体验版协议', // 体验版协议
        en: '',
        ko: '',
        zh: '',
      },
      {
        type: 'home',
        name: '帮助中心首页',
        en: 'https://adoba.gitbook.io/enterprise-guide-center_en/',
        ko: 'https://adoba.gitbook.io/enterprise-guide-center_kr/',
        zh: 'https://adoba.gitbook.io/enterprise-guide-center_zh/',
      },
      {
        type: 'point',
        name: 'point规则',
        en: 'https://adoba.gitbook.io/enterprise-guide-center_en/',
        ko: 'https://adoba.gitbook.io/enterprise-guide-center_kr/',
        zh: 'https://adoba.gitbook.io/enterprise-guide-center_zh/',
      },
      {
        type: 'auth',
        name: 'globalPass认证',
        en: 'https://adoba.gitbook.io/enterprise-guide-center_en#global-pass-verification',
        ko: 'https://adoba.gitbook.io/enterprise-guide-center_kr#global-pass',
        zh: 'https://adoba.gitbook.io/enterprise-guide-center_zh#global-pass-ren-zheng',
      },
      {
        type: 'channel',
        name: 'channel ID',
        en: 'https://adoba.gitbook.io/enterprise-guide-center_en/',
        ko: 'https://adoba.gitbook.io/enterprise-guide-center_kr/',
        zh: 'https://adoba.gitbook.io/enterprise-guide-center_zh/',
      },
      {
        type: 'plan',
        name: 'plan权益',
        en: 'https://adoba.gitbook.io/enterprise-guide-center_en#plan',
        ko: 'https://adoba.gitbook.io/enterprise-guide-center_kr#undefined-2',
        zh: 'https://adoba.gitbook.io/enterprise-guide-center_zh#ji-hua',
      },
      {
        type: 'platform',
        platformId: 4,
        name: 'B站',
        en: 'https://adoba.gitbook.io/enterprise-guide-center_en#bilibili',
        ko: 'https://adoba.gitbook.io/enterprise-guide-center_kr#undefined-4',
        zh: 'https://adoba.gitbook.io/enterprise-guide-center_zh#bi-li-bi-li',
      },
      {
        type: 'platform',
        platformId: 9,
        name: '小红书',
        en: 'https://adoba.gitbook.io/enterprise-guide-center_en#xiaohongshu',
        ko: 'https://adoba.gitbook.io/enterprise-guide-center_kr#undefined-10',
        zh: 'https://adoba.gitbook.io/enterprise-guide-center_zh#xiao-hong-shu',
      },
      {
        type: 'platform',
        platformId: 5,
        name: '西瓜',
        en: 'https://adoba.gitbook.io/enterprise-guide-center_en#xigua-video',
        ko: 'https://adoba.gitbook.io/enterprise-guide-center_kr#undefined-5',
        zh: 'https://adoba.gitbook.io/enterprise-guide-center_zh#xi-gua-shi-pin',
      },
      {
        type: 'platform',
        platformId: 1,
        name: '好看',
        en: 'https://adoba.gitbook.io/enterprise-guide-center_en#haokan-video',
        ko: 'https://adoba.gitbook.io/enterprise-guide-center_kr#undefined-6',
        zh: 'https://adoba.gitbook.io/enterprise-guide-center_zh#hao-kan-shi-pin',
      },
      {
        type: 'platform',
        platformId: 3,
        name: '爱奇艺',
        en: 'https://adoba.gitbook.io/enterprise-guide-center_en#iqiyi',
        ko: 'https://adoba.gitbook.io/enterprise-guide-center_kr#undefined-7',
        zh: 'https://adoba.gitbook.io/enterprise-guide-center_zh#ai-qi-yi',
      },
      {
        type: 'platform',
        platformId: 10,
        name: '快手',
        en: 'https://adoba.gitbook.io/enterprise-guide-center_en#kuaishou',
        ko: 'https://adoba.gitbook.io/enterprise-guide-center_kr#undefined-8',
        zh: 'https://adoba.gitbook.io/enterprise-guide-center_zh#kuai-shou',
      },
      {
        type: 'platform',
        platformId: 6,
        name: '微博',
        en: 'https://adoba.gitbook.io/enterprise-guide-center_en#weibo',
        ko: 'https://adoba.gitbook.io/enterprise-guide-center_kr#undefined-9',
        zh: 'https://adoba.gitbook.io/enterprise-guide-center_zh#wei-bo',
      },
      {
        type: 'platform',
        platformId: 16,
        name: 'QQ小世界',
        en: 'https://adoba.gitbook.io/enterprise-guide-center_en#qq-world',
        ko: 'https://adoba.gitbook.io/enterprise-guide-center_kr#undefined-11',
        zh: 'https://adoba.gitbook.io/enterprise-guide-center_zh#qq-xiao-shi-jie',
      },
    ];

    const language = i18n.language === 'zh' ? 'zh' : i18n.language === 'ko' ? 'ko' : 'en';
    const docLink =
      helpCenterList.find((item) =>
        item.type === 'platform' ? item.platformId === platformId : item.type === type
      )?.[language] ||
      helpCenterList.find((item) =>
        item.type === 'platform' ? item.platformId === platformId : item.type === type
      )?.['zh'] ||
      '';

    return docLink;
  },

  /**
   * 根据当前语言打开 客服中心页面
   */
  handleToCustomerService: () => {
    const url =
      i18n.language === 'zh'
        ? 'https://adoba.gitbook.io/enterprise_helpcenter_zh/'
        : i18n.language === 'ko'
        ? 'https://adoba.gitbook.io/enterprise_helpcenter_kr/'
        : 'https://adoba.gitbook.io/enterprise_helpcenter_en/';
    window.open(url, '_blank');
  },

  handleCustomerServiceUrl: () => {
    const url =
      i18n.language === 'zh'
        ? 'https://adoba.gitbook.io/enterprise_helpcenter_zh/'
        : i18n.language === 'ko'
        ? 'https://adoba.gitbook.io/enterprise_helpcenter_kr/'
        : 'https://adoba.gitbook.io/enterprise_helpcenter_en/';
    return url;
  },

  /**
   * 帮助中心链接
   */
  handleHelpCenterUrl: () => {
    const url =
      i18n.language === 'zh'
        ? 'https://adoba.gitbook.io/enterprise-guide-center_zh/'
        : i18n.language === 'ko'
        ? 'https://adoba.gitbook.io/enterprise-guide-center_kr/'
        : 'https://adoba.gitbook.io/enterprise-guide-center_en/';
    return url;
  },

  /**
   * MCN反馈页面链接
   */
  handleToSolutionUrl: () => {
    const url =
      i18n.language === 'ko'
        ? 'https://docs.google.com/forms/d/1LENxDEgDMN7xMr42oXdKeD96MId8WVMu78t2kQ_XrBU/viewform?edit_requested=true'
        : 'https://docs.google.com/forms/d/e/1FAIpQLSfkb7VKqgVVFJp5c2HRfRgabgNYU_MsefLFDquhVYDov2tpPA/viewform?vc=0&c=0&w=1&flr=0&pli=1';
    return url;
  },
};

export default StringUtils;
